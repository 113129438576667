<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" step="1">
        Cadastro
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="step > 2" step="2">
        Fincanceiro
      </v-stepper-step>

      <!-- <v-divider></v-divider>

      <v-stepper-step step="3">
        Resumo
      </v-stepper-step> -->
    </v-stepper-header>

    <v-stepper-items>
      <!-- Cadastro da Empresa -->
      <v-stepper-content step="1">
        <FormCadastroEmpresa ref="formCadastroEmpresa" />
        <v-btn color="primary" @click="validateFormCadEmpresa" class="mt-3">
          Continuar
        </v-btn>
      </v-stepper-content>

      <!-- Cadastro dos Dados Financeiros -->
      <v-stepper-content step="2">
        <FormCadastroDadosFinanceiros ref="formCadastroDadosFinanceiros" />
        <!-- <v-btn class="mr-5" color="amber darken-3" @click="step--">
          voltar
        </v-btn> -->
        <v-btn
          color="primary"
          @click="validateFormCadFinanceiro(), cadastrarEmpresa()"
        >
          Cadastar
        </v-btn>
      </v-stepper-content>

      <!-- Resumo da campanha -->
      <!-- <v-stepper-content step="3">
        <ResumoCadEmpresa ref="resumoFormCad" />
        <v-btn class="mr-5" color="amber darken-3" @click="step--">
          voltar
        </v-btn>
        <v-btn color="primary">
          Cadastar
        </v-btn>
      </v-stepper-content> -->
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { mapGetters } from "vuex";
import serviceEmpresa from "@/services/empresa";
export default {
  name: "AssistenteEstapasCadastroEmpresa",

  components: {
    FormCadastroEmpresa: () => import("./FormCadastroEmpresa"),
    FormCadastroDadosFinanceiros: () => import("./FormCadastroDadosFinanceiros")
    // ResumoCadEmpresa: () => import("./ResumoCadEmpresa")
  },

  data() {
    return {
      step: 1,
      formEmpresa: {}
    };
  },

  computed: {
    ...mapGetters({
      getFormEmpresa: "empresa/getFormEmpresa"
    })
  },

  methods: {
    validateFormCadEmpresa() {
      const validator = this.$refs.formCadastroEmpresa.validateForm();
      if (validator) {
        this.step++;
      }
    },

    validateFormCadFinanceiro() {
      const validator = this.$refs.formCadastroDadosFinanceiros.validateForm();
      if (validator) {
        this.step++;
      }
    },

    async cadastrarEmpresa() {
      try {
        this.formEmpresa = this.getFormEmpresa;
        this.formEmpresa.nome = this.formEmpresa.nome.toUpperCase();
        this.formEmpresa.nome_fantasia = this.formEmpresa.nome_fantasia.toUpperCase();
        this.formEmpresa.apelido = this.formEmpresa.apelido.toUpperCase();
        this.formEmpresa.email = this.formEmpresa.email.toUpperCase();
        await serviceEmpresa.createEmpresa(this.formEmpresa);
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao cadastrar empresa"
        });
      }
      this.close();
    }
  }
};
</script>
